<template>
    <router-view></router-view>
    <web-r-t-c></web-r-t-c>
    <consent-form></consent-form>
</template>

<script lang='ts' setup>

import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useAuthStore } from "@/stores/auth-store";
import { useLayoutStore } from "@/stores/layout-store";
import { useRouter } from "vue-router";
import { socket } from "./socket";
import { useToastManager } from "./composables/useToastManager";
import { TYPE } from "vue-toastification";
import apiClient from "@/utils/axios";
import WebRTC from "./components/WebRTC.vue";
import ConsentForm from "./components/ConsentForm.vue";
import { Session } from "@/utils/types";
import { useHttpErrorManager } from "@/composables/useHttpErrorManager";

const router = useRouter();
const authStore = useAuthStore();
const layoutStore = useLayoutStore();
const general_notification = ref<string | null>();

router.afterEach((to, from) => {
    apiClient.get("variable/fetchGeneralNotification")
             .then((response : { data : { general_notification_message : string | null } }) => {
                 general_notification.value = response.data.general_notification_message;
                 const local_message = localStorage.getItem("general_notification_message");
                 if (general_notification.value && local_message !== general_notification.value) {
                     localStorage.setItem("general_notification_message", general_notification.value);
                     useToastManager().display(general_notification.value, "warning" as TYPE, false);
                 }
                 else {
                     if (!general_notification.value) {
                         localStorage.removeItem("general_notification_message");
                     }
                 }
             })
             .catch(() => {
                 general_notification.value = null;
             });
});

onMounted(() => {
	window.addEventListener('resize', () => {
		layoutStore.updateBreakpoint()
	} );
	layoutStore.updateBreakpoint();

    if (Notification.permission !== "denied") {
        Notification.requestPermission();
    }

    setTimeout(() => {
        if (authStore.user) {
            const type = authStore.role && ["manager", "admin"].includes(authStore.role) ? "manager" : "user";
            socket.emit("userConnected", [authStore.user.id, type]);
        }
    }, 2000);

    watch(
        () => authStore.user?.id,
        (newUserId, oldUserId) => {
            if (newUserId) {
                socket.on("subscription_updated:member_" + newUserId, (...args) => {
                    const [event] = args;
                    if (event.type == 'SubscriptionUpdated') {
                        let subscription = event.subscription;
                        if (authStore.user) {
                            authStore.user = { ...authStore.user, subscription: subscription };
                        }
                    }
                });
            }

            if (oldUserId) {
                socket.off("subscription_updated:member_" + oldUserId);
            }
        },
        { immediate: true }
    );
});

onBeforeUnmount(() => {
	window.removeEventListener('resize', () => layoutStore.updateBreakpoint());
});

</script>

<style lang="scss" scoped></style>
